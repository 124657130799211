@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 90vh;
  background-color: var.$white;
  border-radius: 20px 20px 0 0;
  padding: 25px 16px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}
