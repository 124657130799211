@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  background-color: var.$white !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  color: var.$black !important;
  box-shadow: 0 0 10px 0 #0000001a !important;
}
