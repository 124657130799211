@use './variables' as var;

@mixin vp-1919 {
  @media (max-width: (1919px)) {
    @content;
  }
}

@mixin vp-1439 {
  @media (max-width: (1439px)) {
    @content;
  }
}

@mixin vp-1368 {
  @media (max-width: (1368px)) {
    @content;
  }
}

@mixin vp-1279 {
  @media (max-width: (1279px)) {
    @content;
  }
}

@mixin vp-1024 {
  @media (max-width: (1024px)) {
    @content;
  }
}

@mixin vp-767 {
  @media (max-width: (767px)) {
    @content;
  }
}

@mixin vp-440 {
  @media (max-width: (440px)) {
    @content;
  }
}

@mixin text($size, $weight, $height) {
  font-size: #{$size}px;
  font-weight: $weight;
  line-height: #{$height}px;
}

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    var.$gray 0px,
    var.$gray-light 40px,
    var.$gray 80px
  );
  background-size: 600px;
}

@mixin primary-gradient {
  background: radial-gradient(
    circle at center,
    #00d4ffcc 0%,
    #090979cc 45%,
    #020024cc 100%
  );
}

@mixin about-block {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  margin: 0 auto;
}

@mixin block {
  background-color: var.$white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 var.$black-10;
}

@mixin hover-interaction {
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px) translateX(5px);
  }

  @include vp-1024 {
    &:hover {
      transform: translateY(-5px);
    }
  }
}

@mixin clamp($lines) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
