@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.empty {
  padding: 16px;
  @include mix.block;
}
