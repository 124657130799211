@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  padding: 16px;
  @include mix.block;
  @include mix.hover-interaction;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  @include mix.vp-1024 {
    height: auto;
    gap: 20px;
  }
}

.title {
  @include mix.text(18, 600, 18);
}

.content {
  display: grid;
  gap: 20px;
}
