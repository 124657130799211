@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  @include mix.text(14, 500, 18);
  background-color: var.$gray;
  padding: 32px 32px 16px 16px;
  border-radius: 10px;
  position: relative;

  &:hover {
    .copy {
      opacity: 1;
    }
  }
}

.copy {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @include mix.vp-1024 {
    opacity: 1;
  }
}
