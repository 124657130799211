@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  @include mix.text(48, 800, 48);

  @include mix.vp-1024 {
    @include mix.text(32, 800, 40);
  }
}
