@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  flex-direction: column;
  background-color: var.$gray-light;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

button.button {
  margin: 0 auto;
  width: 240px;

  @include mix.vp-1024 {
    width: 100%;
  }
}
