@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title {
  @include mix.text(18, 400, 20);
}

.icon {
  width: 24px;
  height: 24px;
}

.rotated {
  transform: rotate(180deg);
}
