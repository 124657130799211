@use 'src/styles/variables' as var;
@use 'src/styles/mixins' as mix;

.wrapper {
  background: #00000066;
  width: 100%;
  height: 100%;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}
